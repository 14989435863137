<template>
    <div>
    <app-loader :value="$apollo.loading"/>
    <v-card flat v-if="orderDetails">
        <v-container>
            <nice-header>
                Order details
                <template slot="extra">
                    {{ orderDetails.hashId }}
                </template>
            </nice-header>
            <v-alert v-if="orderDetails.hireType.toLowerCase() === 'delivery'" color="warning" outlined>
                This order must be delivered to the customer at {{ orderDetails.deliveryAddress }}
            </v-alert>
            <v-alert v-if="orderDetails.status === 'CONFIRMED' && orderDetails.deposit && orderDetails.deposit.status !== 'Card details saved'" type="warning" outlined>
                You cannot start this hire because the customer has not added card details for the deposit. Either remove the deposit require, or contact your customer
            </v-alert>
            <v-alert v-if="multipleStarts || multipleEnds" type="warning" outlined>
                This order has multiple collection and/or return times. Please check the details carefully
            </v-alert>
            <v-alert v-if="orderDetails.status === 'UNCONFIRMED'" type="info" outlined>
                This is an order that you have created manually. The customer has not yet paid for or accepted it.
            </v-alert>
            <v-card-text>
                <v-row>
                    <v-col cols="4">
                        <v-list subheader two-line>
                            <v-subheader>
                                Status and collection
                            </v-subheader>
                            <list-item
                                :title="orderDetails.status.replace('_', ' ')"
                                subtitle="Current status"
                                icon="mdi-list-status"
                            ></list-item>
                            <v-divider inset></v-divider>
                            <list-item
                                :title="multipleStarts ? 'Multiple - see details below' : niceTime(orderDetails.collectionDate)"
                                subtitle="Collection time"
                                icon="mdi-calendar"
                            ></list-item>
                            <v-divider inset></v-divider>
                            <list-item
                                :title="multipleEnds ? 'Multiple - see details below' : niceTime(orderDetails.returnDate)"
                                subtitle="Return time"
                                icon="mdi-keyboard-return"
                            ></list-item>
                        </v-list>
                    </v-col>

                    <v-col cols="4">
                        <v-list subheader two-line>
                            <v-subheader>
                                Customer information
                            </v-subheader>
                            <list-item
                                :title="orderDetails.customer.name"
                                subtitle="Name"
                                icon="mdi-account-circle"
                            ></list-item>
                            <v-divider inset></v-divider>
                            <list-item
                                :title="orderDetails.customer.email"
                                subtitle="Email"
                                icon="mdi-email"
                            ></list-item>
                            <v-divider inset></v-divider>
                            <list-item
                                :title="orderDetails.customer.phoneNumber"
                                subtitle="Phone number"
                                icon="mdi-phone"
                            ></list-item>
                        </v-list>
                    </v-col>
                    <v-col cols="4">

                        <v-list subheader two-line>
                            <v-subheader>
                                Price
                            </v-subheader>
                            <list-item
                                :title="orderDetails.paymentMethod"
                                subtitle="Payment method"
                                icon="mdi-credit-card"
                            ></list-item>
                            <v-divider inset></v-divider>
                            <list-item
                                :title="orderDetails.grandTotal"
                                subtitle="Order total"
                                icon="mdi-cash-register"
                            ></list-item>
                            <v-divider inset v-if="orderDetails.remainingBalance"></v-divider>
                            <list-item
                                v-if="orderDetails.remainingBalance"
                                :title="orderDetails.remainingBalance"
                                subtitle="Remaining balance"
                                icon="mdi-bank"
                            ></list-item>
                            <v-divider inset v-if="orderDetails.hireType.toLowerCase() === 'delivery'"></v-divider>
                            <list-item
                                v-if="orderDetails.hireType.toLowerCase() === 'delivery'"
                                :title="orderDetails.deliveryAddress"
                                subtitle="Delivery address"
                                icon="mdi-bank"
                            ></list-item>
                        </v-list>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <request-deposit
                    v-if="!orderDetails.deposit"
                    :disabled="['PENDING','CONFIRMED'].indexOf(orderDetails.status) === -1 || !store.billingEnabled"
                    :order="orderDetails"
                    @create="updateOrder"
                />
                <view-deposit v-else @capture="updateOrder" :order="orderDetails"/>
                <resend-quote v-if="orderDetails.status === 'UNCONFIRMED'" :order="orderDetails"/>
                <v-spacer></v-spacer>
                <mark-balance-paid :order="orderDetails" @changed="updateOrder" v-if="orderDetails.remainingBalance"/>
                <update-order-button
                    v-if="orderDetails.status === 'PENDING'"
                    :order="orderDetails"
                    new-status="CONFIRMED"
                    @changed="updateOrder"
                >
                    Confirm order
                </update-order-button>
                <cancel-order-button
                    v-if="['PENDING','CONFIRMED', 'UNCONFIRMED'].indexOf(orderDetails.status) > -1"
                    :order="orderDetails"
                    @cancelled="updateOrder"
                />
                <issue-refund-button
                    v-if="orderDetails.status === 'CANCELLED' && !!orderDetails.stripeSessionId"
                    :order="orderDetails"
                    @issued="updateOrder"
                />
                <update-order-button
                    v-if="orderDetails.status === 'CONFIRMED'"
                    :order="orderDetails"
                    newStatus="IN_PROGRESS"
                    :disabled="orderDetails.deposit && orderDetails.deposit.status !== 'Card details saved'"
                    @changed="updateOrder"
                >
                    Start Hire
                </update-order-button>
                <complete-hire-button
                    v-if="orderDetails.status === 'IN_PROGRESS'"
                    :order="orderDetails"
                    newStatus="COMPLETED"
                    @changed="updateOrder"
                >
                    Mark as returned
                </complete-hire-button>

            </v-card-actions>
        </v-container>
    </v-card>

    <v-card tile flat outlined class="my-5" v-if="orderDetails">
        <v-container>
            <nice-header>Products ordered</nice-header>
            <v-card-text>
                <template v-for="(booking, i) in orderDetails.lineItems">
                    <v-container :key="i">
                        <nice-header small v-if="multipleStarts || multipleEnds">
                            Collection/return: {{ booking.startTime | niceTime }} > {{ booking.endTime | niceTime }}
                        </nice-header>
                            <v-simple-table>
                                <thead>
                                <tr>
                                    <th class="text-left title">Product</th>
                                    <th class="text-left title">Quantity</th>
                                    <th class="text-left title">{{store.labelOne}}</th>
                                    <th class="text-left title">{{store.labelTwo}}</th>
                                    <th class="text-left title">{{store.labelThree}}</th>
                                    <th class="text-left title">Subtotal</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="item in booking.lineItems" :key="item.id">
                                    <td>
                                        <div class="py-5">
                                            <div>{{ item.plan.product.name }} <v-icon x-small @click="$router.push(`/product/${item.plan.product.id}`)">mdi-launch</v-icon></div>
                                            <div><v-icon x-small>mdi-tag</v-icon> {{item.plan.product.category.name}}</div>
                                        </div>
                                    </td>

                                    <td>
                                        {{ item.assets.length }}
                                    </td>
                                    <td>
                                        {{ item.assets[0].size }}
                                    </td>
                                    <td>
                                        {{ item.assets[0].brand }}
                                    </td>
                                    <td>
                                        {{ item.assets[0].gender }}
                                    </td>
                                    <td>
                                        {{ item.subtotal }}
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-container>
                </template>


            </v-card-text>
            <nice-header v-if="orderDetails.extras.length > 0">
                Extras
            </nice-header>
            <v-card-text v-if="orderDetails.extras.length > 0">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="text-left title">Description</th>
                            <th class="text-left title">Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="extra in orderDetails.extras" :key="extra.id">
                            <td>{{extra.description}}</td>
                            <td>{{ orderDetails.currency.symbol }} {{extra.value}}</td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <sku-button :order="orderDetails"></sku-button>
            </v-card-actions>
        </v-container>

    </v-card>
    </div>
</template>

<script>
    import { user, orderDetails, stores } from '@/graphql'
    import MarkBalancePaid from '@/components/MarkBalancePaid.vue'
    import moment from 'moment'
    import { mapState } from 'vuex'

    export default {
        components: { MarkBalancePaid },
        apollo: {
            stores,
            user,
            orderDetails: {
                query: orderDetails,
                variables() {
                    return {pk: this.$route.params.id}
                }
            }
        },
        filters: {
            niceTime(val) {
                return moment.parseZone(val).format('LLL')
            }
        },
        computed: {
            ...mapState(['selectedStore']),
            store () {
                if (this.selectedStore) {
                    return this.stores.find(store => store.hashId === this.selectedStore)
                }
                return null
            },
            multipleStarts () {
                if (this.orderDetails && this.orderDetails.lineItems.length > 0) {
                   return new Set(this.orderDetails.lineItems.map((li) => li.startTime)).size > 1
                }
                return false
            },
            multipleEnds () {
                if (this.orderDetails && this.orderDetails.lineItems.length > 0) {
                  return new Set(this.orderDetails.lineItems.map((li) => li.endTime)).size > 1
                }
                return false
            }
        },
        data: () => ({
            confirming: false,
            cancelling: false,
        }),
        methods: {
            updateOrder() {
                this.$apollo.queries.orderDetails.refetch()
            },
            niceTime (val) {
                return moment.parseZone(val).calendar()
            }
        }

    }
</script>
