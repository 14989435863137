<template>
  <v-dialog v-model="dialog" max-width="400">
    <v-snackbar color="error" v-model="error">{{errorMessage}}</v-snackbar>
    <template v-slot:activator="{ on }">
      <v-btn 
        rounded
        outlined
        color="info"
        v-on="on"
      >Mark as balance paid</v-btn>
    </template>
    <v-card flat>
      <nice-header>Mark as balance paid</nice-header>
      <v-card-text>
        You are about to mark this order as having its remaining balance of {{ order.remainingBalance }} paid.
        This cannot be undone. Proceed?
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          rounded
          outlined
          :loading="loading"
          color="success"
          @click="markPaid"
        >
          Mark as paid
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { markOrderPaid } from '@/graphql'
export default {
  props: ['order'],
  data: () => ({
    loading: false,
    dialog: false,
    error: false,
    errorMessage: null
  }),
  methods: {
    markPaid () {
      this.loading = true
      this.$apollo.mutate({
        mutation: markOrderPaid,
        variables: {
          order: this.order.hashId
        }
      }).then((result) => {
        if (result) {
          this.dialog = false
          this.loading = false
          this.$emit('changed')
        }
      }).catch((error) => {
        this.error = true
        this.errorMessage = error.message.replace('GraphQL error', '')
        this.loading = false
      })
    }
  }
}
</script>
  
